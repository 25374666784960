import {LOGO} from "../../assets/images/Images";
import {NavLink, useNavigate} from "react-router-dom";
import {RiMenu4Line} from "react-icons/ri";
import {AiOutlineClose} from "react-icons/ai";
import $ from 'jquery'
import {FacebookOutlined, InstagramOutlined, MailOutlined, PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {BiNavigation} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {DownOutlined} from '@ant-design/icons';
import {Dropdown, Space} from 'antd';
import {useEffect, useState} from "react";

export const Menu = () => {
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const {t, i18n} = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };
    const items = [
        {
            label: <button onClick={() => {
                setSelectedLanguage("English");
                changeLanguage('en');
            }} className={'btn-language'}>English</button>,
            key: '0',
        },
        {
            label: <button onClick={() => {
                setSelectedLanguage("German");
                changeLanguage('de');
            }} className={'btn-language'}>German</button>,
            key: '1',
        },
        {
            label: <button onClick={() => {
                setSelectedLanguage("Dutch");
                changeLanguage('nl');
            }} className={'btn-language'}>Dutch</button>,
            key: '2',
        },
        {
            label: <button onClick={() => {
                setSelectedLanguage("French");
                changeLanguage('fr');
            }} className={'btn-language'}>French</button>,
            key: '3',
        },
    ];

    useEffect(() => {
        $('#mobile_menu a').click(function (e) {
            $('#mobile_menu').removeClass('show');
        })
    }, []);

    return (
        <>
            <div className="top-bar">
                <div className="social-icons">
                    <ul>
                        <li>
                            <a href="https://instagram.com/villafriesland"><InstagramOutlined/></a>
                        </li>
                    </ul>
                </div>
                <div className="contact-details">
                    <ul>
                        <li>
                            <a href="https://wa.me/+31612420600">
                                <PhoneOutlined/>
                                <span>+31(612) 420600</span>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:ben@boenk.nl">
                                <MailOutlined/>
                                <span>ben@boenk.nl</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://maps.app.goo.gl/aK6vrHtvZyRLXYce9" target="_blank">
                                <BiNavigation/>
                                <span>{t("address")}</span>
                            </a>
                        </li>
                        <li>
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                trigger={['click']}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {selectedLanguage}
                                        <DownOutlined className="btn-language-icon"/>
                                    </Space>
                                </a>
                            </Dropdown>
                            {/*<button onClick={() => changeLanguage("fr")}>Change Language</button>*/}
                        </li>
                    </ul>
                </div>
            </div>
            <div className={'navbar'}>
                <div className="navbar-brand">
                    <NavLink to="/">
                        <img src={LOGO} alt="Brand Logo"/>
                    </NavLink>
                </div>
                <div className="navbar-menu-container" id={'mobile_menu'}>
                    <button className="btn-mobile-menu btn-close" onClick={() => {
                        $('#mobile_menu').removeClass('show');
                    }}>
                        <AiOutlineClose/>
                    </button>
                    <div className="navbar-menu">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to='/'>{t("home")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/apartment'>{t("stay")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/facilities'>{t("facility")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/book-now'>{t("bookVilla")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/contact'>{t("contact-us")}</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <button className="btn-mobile-menu" onClick={() => {
                    $('#mobile_menu').addClass('show');
                }}>
                    <RiMenu4Line/>
                </button>
            </div>
        </>
    )
}
