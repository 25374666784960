import React, {useState, useTransition} from 'react';
import {
    additionalPhoto,
    APT_1, backYard, bath1, bath2, bath3, bath4,
    bed1,
    bed2,
    bed3,
    bed4,
    diningArea, exterior,
    fullKitchen, halfBath, laundryArea,
    livingRoom1,
    livingRoom2, patio,
    APT_2,
    APT_3,
    APT_4,
    APT_5,
    APT_6,
    APT_7,
    APT_8,
    APT_9,
    APT_10,
    APT_11,
    APT_12,
    APT_13,
    APT_14,
    APT_15,
    APT_16,
    APT_17,
    APT_18,
    APT_19,
    APT_20,
    APT_21,
    APT_22,
    APT_23,
    APT_24,
    APT_25,
    APT_26,
    APT_28,
    APT_29,
    APT_30,
    APT_31,
    APT_32,
    APT_33,
    APT_34,
    APT_35,
    APT_36,
    APT_37,
    APT_38,
    APT_39,
    APT_40,
    APT_41,
    APT_42,
    APT_43
} from "../../assets/images/Images";
import {NavLink} from "react-router-dom";
import {CalendarOutlined} from "@ant-design/icons";
import ImgsViewer from "react-images-viewer";
import {useTranslation} from "react-i18next";

const StickySection = () => {
    return (
        <div className="section">
            <div className="name">Section Name</div>
            <div className="images">
                <img src={APT_1} alt="Image 1"/>
                <img src={APT_1} alt="Image 2"/>
                <img src={APT_1} alt="Image 3"/>
            </div>
        </div>
    );
};

function Apartment() {
    const {t} = useTranslation();
    const [currentImage, setCurrentImage] = useState('');
    const [isViewImage, setIsViewImage] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isGalleryView, setIsGalleryView] = useState(false);
    const [gallery, setGallery] = useState([
        {src: APT_2},
        {src: APT_3},
        {src: APT_4},
        {src: APT_8},
        {src: APT_11},
        {src: APT_16},
        {src: APT_20},
        {src: APT_23},
        {src: APT_25},
        {src: APT_26},
        {src: APT_28},
        {src: APT_29},
        {src: APT_32},
        {src: APT_33},
        {src: APT_34},
        {src: APT_39},
        {src: APT_40},
        {src: APT_43},
    ]);
    const openViewer = (index) => {
        setCurrentIndex(index);
        setIsGalleryView(true);
    };

    // Handle closing the viewer
    const closeViewer = () => {
        setIsGalleryView(false);
    };

    // Handle Next Image
    const onNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % gallery.length);
    };

    // Handle Previous Image
    const onPrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + gallery.length) % gallery.length);
    };
    return (
        <>
            <ImgsViewer
                imgs={[
                    {src: currentImage},
                    // {src: livingRoom1},
                    // {src: livingRoom2},
                    // {src: bed1},
                    // {src: bed2},
                    // {src: bed3},
                    // {src: bed4},
                    // {src: fullKitchen},
                    // {src: diningArea},
                    // {src: bath1},
                    // {src: bath2},
                    // {src: bath3},
                    // {src: bath4},
                    // {src: halfBath},
                    // {src: backYard},
                    // {src: exterior},
                    // {src: laundryArea},
                    // {src: APT_1},
                    // {src: additionalPhoto},
                ]}
                isOpen={isViewImage}
                onClose={() => setIsViewImage(false)}
            />
            <ImgsViewer
                currImg={currentIndex}
                imgs={gallery}
                onClose={closeViewer}
                isOpen={isGalleryView}
                onClickNext={onNextClick}
                onClickPrev={onPrevClick}
                preloadNextImg={true}
            />
            <section className="section-about" style={{alignItems: "flex-start"}}>
                <div className="section-about-content">
                    <h2>{t("home_heading")}</h2>
                    <p>{t("description")}</p>
                    <h4>{t("about")}</h4>
                    <p>{t("about-content")}</p>
                    <h4>{t("guest-access")}</h4>
                    <p>{t("guest-access-content")}</p>
                    <p>{t("parking-content")}</p>
                    <h4>{t("other-thing")}</h4>
                    <p>{t("other-thing-content")}</p>
                    <p>{t("dog")}</p>
                    <p>{t("smoking")}</p>
                    <p>{t("babies")}</p>
                    <p>{t("supboard")}</p>
                    <p>{t("evcharger")}</p>
                    <h4>{t("house-rule")}</h4>
                    <p>{t("house-rule-content")}</p>
                    <NavLink to={'/book-now'} className={'btn-book'}><CalendarOutlined/> {t("book-now")}</NavLink>
                </div>
                <div className="section-about-image">
                    <img src={APT_3} alt="Apartment Image"/>
                    <a href='https://maps.app.goo.gl/aK6vrHtvZyRLXYce9' className={'btn-book'} style={{ marginTop: "20px" }}> {t("Get Directions")}</a>
                </div>
            </section>
            <div className="section-image-gallery">
                <h1>{t("photo-tour")}</h1>
                <div className="image-gallery">
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(0)
                            setIsGalleryView(true)
                        }}>
                            <img src={livingRoom1} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("livingRoom")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(1)
                            setIsGalleryView(true)
                        }}>
                            <img src={livingRoom2} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("livingRoom2")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(2)
                            setIsGalleryView(true)
                        }}>
                            <img src={fullKitchen} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("fullKitchen")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(3)
                            setIsGalleryView(true)
                        }}>
                            <img src={diningArea} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("diningArea")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(4)
                            setIsGalleryView(true)
                        }}>
                            <img src={bed1} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bed1")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(5)
                            setIsGalleryView(true)
                        }}>
                            <img src={bed2} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bed2")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(6)
                            setIsGalleryView(true)
                        }}>
                            <img src={bed3} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bed3")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(7)
                            setIsGalleryView(true)
                        }}>
                            <img src={bed4} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bed4")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(8)
                            setIsGalleryView(true)
                        }}>
                            <img src={bath1} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bath1")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(9)
                            setIsGalleryView(true)
                        }}>
                            <img src={bath2} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bath2")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(10)
                            setIsGalleryView(true)
                        }}>
                            <img src={bath3} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bath3")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(11)
                            setIsGalleryView(true)
                        }}>
                            <img src={bath4} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("bath4")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(12)
                            setIsGalleryView(true)
                        }}>
                            <img src={halfBath} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("halfBathroom")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(13)
                            setIsGalleryView(true)
                        }}>
                            <img src={backYard} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("backyard")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(14)
                            setIsGalleryView(true)
                        }}>
                            <img src={patio} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("patio")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(15)
                            setIsGalleryView(true)
                        }}>
                            <img src={laundryArea} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("laundryArea")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(16)
                            setIsGalleryView(true)
                        }}>
                            <img src={exterior} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("exterior")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image-card-container">
                        <div className="gallery-image-card" onClick={() => {
                            setCurrentIndex(17)
                            setIsGalleryView(true)
                        }}>
                            <img src={additionalPhoto} alt="Apartment Image"/>
                            <div className="gallery-image-card-footer">
                                <span>{t("outer-view")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-house-detail">
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("livingRoom")}</h2>
                        {/*<ul>*/}
                        {/*    <li>Board games</li>*/}
                        {/*    <li>Heating</li>*/}
                        {/*    <li>Indoor fireplace</li>*/}
                        {/*    <li>Lake access</li>*/}
                        {/*    <li>Mosquito net</li>*/}
                        {/*    <li>Private living room</li>*/}
                        {/*    <li>Sound system</li>*/}
                        {/*    <li>Waterfront</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-row">
                            <img src={APT_2} alt="Apartment Image"/>
                            <img src={APT_3} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("fullKitchen")}</h2>
                        {/*<ul>*/}
                        {/*    <li>Baking sheet</li>*/}
                        {/*    <li>Barbecue utensils</li>*/}
                        {/*    <li>Blender</li>*/}
                        {/*    <li>Coffee</li>*/}
                        {/*    <li>Coffee maker</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_4} alt="Apartament Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_5} alt="Apartment Image"/>
                            <img src={APT_6} alt="Apartment Image"/>
                        </div>
                        <div className="house-detail-image-single-image">
                            <img src={APT_7} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("diningArea")}</h2>
                        {/*<ul>*/}
                        {/*    <li>Dining Table</li>*/}
                        {/*    <li>Heating</li>*/}
                        {/*    <li>Wine glasses</li>*/}
                        {/*    <li>Arcade games</li>*/}
                        {/*    <li>Board games</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_8} alt="Apartament Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_9} alt="Apartment Image"/>
                            <img src={APT_10} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bed1")}</h2>
                        {/*<ul>*/}
                        {/*    <li>2 single beds</li>*/}
                        {/*    <li>Air conditioning</li>*/}
                        {/*    <li>Clothing storage</li>*/}
                        {/*    <li>Extra pillows and blankets</li>*/}
                        {/*    <li>Hanger</li>*/}
                        {/*    <li>Heating</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_11} alt="Apartament Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_12} alt="Apartment Image"/>
                            <img src={APT_13} alt="Apartment Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_14} alt="Apartment Image"/>
                            <img src={APT_15} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bed2")}</h2>
                        {/*<ul>*/}
                        {/*    <li>Double bed</li>*/}
                        {/*    <li>Air conditioning</li>*/}
                        {/*    <li>Clothing storage</li>*/}
                        {/*    <li>Extra pillows and blankets</li>*/}
                        {/*    <li>Hanger</li>*/}
                        {/*    <li>Heating</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_16} alt="Apartament Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_17} alt="Apartment Image"/>
                            <img src={APT_19} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bed3")}</h2>
                        {/*<ul>*/}
                        {/*    <li>Double bed</li>*/}
                        {/*    <li>Air conditioning</li>*/}
                        {/*    <li>Clothing storage</li>*/}
                        {/*    <li>Extra pillows and blankets</li>*/}
                        {/*    <li>Hanger</li>*/}
                        {/*    <li>Heating</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_20} alt="Apartament Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_21} alt="Apartment Image"/>
                            <img src={APT_22} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bed4")}</h2>
                        {/*<ul>*/}
                        {/*    <li>Double bed</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-row">
                            <img src={APT_23} alt="Apartment Image"/>
                            <img src={APT_24} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bath1")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_25} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bath2")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_26} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bath3")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_28} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("bath4")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_29} alt="Apartment Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_30} alt="Apartment Image"/>
                            <img src={APT_31} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("halfBathroom")}</h2>
                        {/*<ul>*/}
                        {/*    <li>Body soap</li>*/}
                        {/*    <li>Bidet</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_32} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("backyard")}</h2>
                        {/*<ul>*/}
                        {/*    <li>BBQ grill</li>*/}
                        {/*    <li>Fire pit</li>*/}
                        {/*    <li>Outdoor dining area</li>*/}
                        {/*    <li>Outdoor furniture</li>*/}
                        {/*    <li>Outdoor Kitchen</li>*/}
                        {/*    <li>Barbecue utensils</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-row">
                            <img src={APT_33} alt="Apartment Image"/>
                            <img src={APT_34} alt="Apartment Image"/>
                        </div>
                        <div className="house-detail-image-single-image">
                            <img src={APT_35} alt="Apartment Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_36} alt="Apartment Image"/>
                            <img src={APT_37} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("patio")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_38} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("laundryArea")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_39} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("exterior")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_40} alt="Apartment Image"/>
                        </div>
                        <div className="house-detail-image-row">
                            <img src={APT_41} alt="Apartment Image"/>
                            <img src={APT_42} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
                <div className="house-detail-container">
                    <div className="house-detail">
                        <h2>{t("additional-photo")}</h2>
                    </div>
                    <div className="house-detail-image">
                        <div className="house-detail-image-single-image">
                            <img src={APT_43} alt="Apartment Image"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-booking">
                <h1>{t("book-now")}</h1>
                <p>{t("apartment-footer")}</p>
                <NavLink to={'/book-now'} className={'btn-book'}>{t("book-now")}</NavLink>
            </div>
        </>
    );
}

export default Apartment;
